import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Form from './FAForm';
import FAFooter from './FAFooter';

const SignUpScreen = () => {
    return (
        <>
            <Row className="fa-header-row1">
                <Col className="d-flex justify-content-center">
                    <h3>Free Trial</h3>
                </Col>
            </Row>
            <Container className="fa-container py-2" fluid="xl">
                <Form />
            </Container>
            <FAFooter/>
        </>
    );
}

export default SignUpScreen;