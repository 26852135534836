import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { AiFillPhone } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { FaBuilding } from 'react-icons/fa';
import { SocialIcon } from 'react-social-icons';

const FAInfo = () => {
    return (
        <IconContext.Provider value={{className:"contact-icons"}}>
            <Row className="fa-footer-title1">
                <Col className="d-flex justify-content-center">
                    <h3>Hours</h3>
                </Col>
            </Row>
            <Container className="fa-container py-3 py-md-5" fluid="xl">
                <Row className="fa-hours">
                    <Col xs={12} md={6} className="mb-2 mb-md-0">
                        <Row className="justify-content-center text-center">
                            <b>Weekdays</b>
                        </Row>
                        <Row className="justify-content-center text-center">
                            5:00am - 11:00pm
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row className="justify-content-center text-center">
                            <b>Weekends</b>
                        </Row>
                        <Row className="justify-content-center text-center">
                            7:00am - 8:00pm
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Row className="fa-footer-title1 d-flex justify-content-center">
                <h3>Contact</h3>
            </Row>
            <Row className="d-flex justify-content-center">
                <p className="fa-contact-info">
                    <FaBuilding className="fa-contact-icon"/><br/>
                    153 Temple Hill Road<br/>
                    New Windsor, NY<br/><br/>
                    <AiFillPhone className="fa-contact-icon"/><br/>
                    <a href="tel:8457813077" className="text-light">(845) 781-3077</a><br/><br/>
                    <GrMail className="fa-contact-icon"/><br/>
                    <a href="mailto:coachsanter1031@yahoo.com" className="text-light">coachsanter@yahoo.com</a><br/>
                </p>
            </Row>
            <Row className="d-flex justify-content-center" style={{marginBottom: "1.5em"}}>
                <div className="fa-contact-info">
                    <SocialIcon className="fa-social-button" url="https://www.facebook.com/fitnessacademyHV/"/>
                    <SocialIcon className="fa-social-button" url="https://twitter.com/fit_academy_hv" />
                    <SocialIcon className="fa-social-button" url="https://www.instagram.com/_fitness.academy_/" /> 
                </div>
            </Row>
        </IconContext.Provider>
    );
}

export default FAInfo;