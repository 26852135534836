import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FAInfo from './FAInfo';

const FAFooter = () => {
    return (
        <Col className="fa-footer" id="fa-footer">
            <FAInfo/>
            <Row className="fa-footer-bottom d-flex justify-content-center">
                <p className="mb-1">Fitness Academy Hudson Valley © {new Date().getFullYear()}</p>
            </Row>
            <Row className="fa-footer-bottom d-flex justify-content-center" style={{paddingTop:"0em"}}>
                <Link to="/admin" className="fa-admin-link text-light">Admin</Link>
            </Row>
        </Col>
    );
}

export default FAFooter;