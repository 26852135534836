import React from 'react';
import { Row, Col, Image, Container, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import FAFooter from './FAFooter';

const HomeScreen = (props) => {

    const bootcampSignUp = () => {
        window.open("https://checkout.square.site/pay/af4bdc1e-d5d2-4228-b144-e5819e79c56f?t=1602211884.001827", "_blank");
    }

    return (
        <>
            <Container className="fa-container" fluid="xl">
                <Row className="fa-logo-header">
                    <Col>
                        <Image
                            src="/fa-logo4.png"
                            className="img-responsive w-100 h-auto"
                            alt="Fitness Academy"
                        />
                    </Col>
                </Row>
            </Container>
            <Row className="fa-header-row1">
                <Col className="fa-header-col1 d-flex justify-content-center">
                    <h3 className="fa-header">Our job is to help you reach your goal!</h3>
                </Col>                
            </Row>
            <Container className="fa-container" fluid="xl">
                <Row className="fa-video-row my-5 mt-md-3 mb-md-0">
                    <Col className="d-flex justify-content-center">
                        <ReactPlayer
                            className="fa-video-player"
                            url='videos/Video1.mp4'
                            playing
                            muted
                            config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
                            loop
                            playsinline
                        />
                    </Col>
                </Row>
                <Row className="fa-paragraph-row1">
                    <Col>
                        <p className="fa-paragraph">
                            <b>OUR GOAL:</b><br/>
                            To motivate clients to meet and exceed their fitness goals, encourage networking and personal development, 
                            build an inclusive and diverse fitness community, and educate our clients on better nutritional and lifestyle habits.
                        </p>
                    </Col>
                </Row>
                <Row className="fa-video-row">
                    <Col className="d-flex justify-content-center">
                        <ReactPlayer
                            className="fa-video-player"
                            url='videos/Video2.mp4'
                            playing
                            muted
                            config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
                            loop
                            playsinline
                        />
                    </Col>
                </Row>
                <Row className="fa-paragraph-row1">
                    <Col>
                        <p className="fa-paragraph">
                            <b>OUR MISSION:</b><br/> 
                            At The Fitness Academy our mission is simple - to deliver the BEST experiences possible through integrated group training, 
                            personal training, balanced nutrition, and group engagement activities.
                        </p>
                    </Col>
                </Row>
                <Row className="fa-video-row">
                    <Col className="d-flex justify-content-center">
                        <ReactPlayer
                            className="fa-video-player"
                            url='videos/Video3.mp4'
                            playing
                            muted
                            config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
                            loop
                            playsinline
                        />
                    </Col>
                </Row>
                <Row className="fa-paragraph-row1">
                    <Col>
                        <p className="fa-paragraph">    
                            <b>Where</b> you train says everything about <b>how</b> you train!
                        </p>
                    </Col>
                </Row>
                <Row className="fa-video-row">
                    <Col className="d-flex justify-content-center">
                        <ReactPlayer
                            className="fa-video-player"
                            url='videos/Video4.mp4'
                            playing
                            muted
                            config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
                            loop
                            playsinline
                        />
                    </Col>
                </Row>
                <Row className="fa-paragraph-row1 mb-4 mb-md-0">
                    <Col>
                        <p className="fa-paragraph">
                            We offer everything the chains offer.... and what they don&apos;t, <b>guaranteed</b>! So why pay more for less?!
                        </p>
                    </Col>
                </Row>
            </Container>
            <Row className="fa-header-row1">
                <Col className="d-flex justify-content-center">
                    <h3>Bootcamp</h3>
                </Col>
            </Row>
            <Container className="fa-container py-2" fluid="xl">
                <Row className="fa-paragraph-row1">
                    <Col className="d-flex justify-content-center">
                        <p className="fa-paragraph">
                            Burn <b>500-1000</b> calories with our dynamic and smart workouts that are focused on building lean muscle and torching fat! 
                            Our training is so effective that you continue burning fat up to <b>48 hrs</b> post workout! 
                            Our Bootcamp workouts use only the most proven methods of strength training, interval &amp; metabolic style training.
                        </p>
                    </Col>
                </Row>
                <Row className="fa-paragraph-row1">
                    <Col className="d-flex justify-content-center">
                        <p className="fa-paragraph">
                        All of our Trainers have certifications from nationally accredited programs. 
                        With experiences from Strength &amp; Conditioning Coaches at the collegiate level, Masters Degrees in Exercise Science, 
                        with over 20yrs experience in the Fitness industry! We hold our trainers to the highest standard in the industry &amp; it shows! 
                        </p>
                    </Col>
                </Row>
                <Row className="fa-paragraph-row1">
                    <Col className="d-flex justify-content-center">
                        <Button className="fa-button" block size="lg" variant="primary" onClick={bootcampSignUp}>
                            Sign Up
                        </Button>
                    </Col>
                </Row>
            </Container>
            <FAFooter/>
        </>
    );
}

export default HomeScreen;